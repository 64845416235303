"use client";

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { motion, AnimatePresence } from "motion/react";

type LightboxContextType = {
  isLightboxOpen: boolean;
  setIsLightboxOpen: React.Dispatch<React.SetStateAction<boolean>>;
  lightboxSrc: string | null;
  setLightboxSrc: React.Dispatch<React.SetStateAction<string | null>>;
  clickPosition: { x: number; y: number } | null;
  setClickPosition: React.Dispatch<
    React.SetStateAction<{ x: number; y: number } | null>
  >;
};

const LightboxContext = createContext<LightboxContextType | undefined>(
  undefined,
);

// The Lightbox UI component
const Lightbox = () => {
  const { isLightboxOpen, setIsLightboxOpen, lightboxSrc, clickPosition } =
    useLightbox();

  return (
    <AnimatePresence>
      {isLightboxOpen && lightboxSrc && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 p-4 backdrop-blur-sm"
          onClick={() => setIsLightboxOpen(false)}
        >
          <motion.div
            className="relative max-h-[90vh] max-w-[90vw] overflow-hidden rounded-lg"
            initial={{
              scale: 0.2,
              opacity: 0.8,
              x: clickPosition ? clickPosition.x - window.innerWidth / 2 : 0,
              y: clickPosition ? clickPosition.y - window.innerHeight / 2 : 0,
            }}
            animate={{
              scale: 1,
              opacity: 1,
              x: 0,
              y: 0,
            }}
            exit={{
              scale: 0.2,
              opacity: 0,
              x: clickPosition ? clickPosition.x - window.innerWidth / 2 : 0,
              y: clickPosition ? clickPosition.y - window.innerHeight / 2 : 0,
            }}
            transition={{
              type: "spring",
              damping: 25,
              stiffness: 300,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <motion.img
              src={lightboxSrc}
              alt="Lightbox image"
              className="h-auto max-h-[90vh] w-auto max-w-[90vw] object-contain"
            />
            <motion.button
              className="absolute right-2 top-2 rounded-full bg-black/50 p-2 text-white hover:bg-black/70"
              onClick={(e) => {
                e.stopPropagation();
                setIsLightboxOpen(false);
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </motion.button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const LightboxProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxSrc, setLightboxSrc] = useState<string | null>(null);
  const [clickPosition, setClickPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  useEffect(() => {
    const scrollShadow = document.querySelector(".scroll-shadow");
    if (scrollShadow) {
      if (isLightboxOpen) {
        scrollShadow.classList.add("overflow-y-hidden");
      } else {
        scrollShadow.classList.remove("overflow-y-hidden");
      }
    }
  }, [isLightboxOpen]);

  return (
    <LightboxContext.Provider
      value={{
        isLightboxOpen,
        setIsLightboxOpen,
        lightboxSrc,
        setLightboxSrc,
        clickPosition,
        setClickPosition,
      }}
    >
      {children}
      <Lightbox />
    </LightboxContext.Provider>
  );
};

export const useLightbox = () => {
  const context = useContext(LightboxContext);
  if (context === undefined) {
    throw new Error("useLightbox must be used within a LightboxProvider");
  }
  return context;
};
